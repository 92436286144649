/* tslint:disable */
 
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ApprovalRequirementEnum {
  FINAL_PROJECT = "FINAL_PROJECT",
  NOT_REQUIREMENT = "NOT_REQUIREMENT",
  QUIZ_EVALUATION = "QUIZ_EVALUATION",
}

export enum CertificateTypeEnum {
  ACADEMY_SKILL_PATH = "ACADEMY_SKILL_PATH",
  B2B_TRACK = "B2B_TRACK",
  CAREER = "CAREER",
  CERTIFICATION = "CERTIFICATION",
  COURSE = "COURSE",
}

export enum ContactSourceEnum {
  SOURCE_B2B_LANDING_MENU_MOBILE = "SOURCE_B2B_LANDING_MENU_MOBILE",
  SOURCE_B2B_LAST_SECTION = "SOURCE_B2B_LAST_SECTION",
  SOURCE_BLOG_ARTICLE_DETAIL = "SOURCE_BLOG_ARTICLE_DETAIL",
  SOURCE_BLOG_BANNER = "SOURCE_BLOG_BANNER",
  SOURCE_BLOG_CATEGORIES = "SOURCE_BLOG_CATEGORIES",
  SOURCE_BLOG_DETAIL = "SOURCE_BLOG_DETAIL",
  SOURCE_BLOG_HOME = "SOURCE_BLOG_HOME",
  SOURCE_CALL_CENTER_CATALOG_POPUP = "SOURCE_CALL_CENTER_CATALOG_POPUP",
  SOURCE_CALL_CENTER_DIALOG_BLOG = "SOURCE_CALL_CENTER_DIALOG_BLOG",
  SOURCE_CALL_CENTER_DIALOG_BLOG_DETAIL = "SOURCE_CALL_CENTER_DIALOG_BLOG_DETAIL",
  SOURCE_CALL_CENTER_DIALOG_HOME = "SOURCE_CALL_CENTER_DIALOG_HOME",
  SOURCE_CALL_CENTER_HOME_CTA_BUTTON = "SOURCE_CALL_CENTER_HOME_CTA_BUTTON",
  SOURCE_CAMPAIGN_LANDING_MENU = "SOURCE_CAMPAIGN_LANDING_MENU",
  SOURCE_CHECKOUT = "SOURCE_CHECKOUT",
  SOURCE_CLASSROOM = "SOURCE_CLASSROOM",
  SOURCE_COUPON_PREMIUM = "SOURCE_COUPON_PREMIUM",
  SOURCE_COURSE_DETAIL_MEMBERSHIP_POPUP = "SOURCE_COURSE_DETAIL_MEMBERSHIP_POPUP",
  SOURCE_COURSE_FREE_LANDING = "SOURCE_COURSE_FREE_LANDING",
  SOURCE_COURSE_FREE_OF_THE_DAY = "SOURCE_COURSE_FREE_OF_THE_DAY",
  SOURCE_COURSE_OPTION = "SOURCE_COURSE_OPTION",
  SOURCE_DEGREE = "SOURCE_DEGREE",
  SOURCE_DOWNLOAD_FREE_RESOURCE = "SOURCE_DOWNLOAD_FREE_RESOURCE",
  SOURCE_HOME_HERO = "SOURCE_HOME_HERO",
  SOURCE_HOME_PHONE_NUMBER = "SOURCE_HOME_PHONE_NUMBER",
  SOURCE_HOME_POP_UP = "SOURCE_HOME_POP_UP",
  SOURCE_HOME_TOP_BANNER = "SOURCE_HOME_TOP_BANNER",
  SOURCE_HOME_USER_PAYG = "SOURCE_HOME_USER_PAYG",
  SOURCE_LANDING_LIVE = "SOURCE_LANDING_LIVE",
  SOURCE_LANDING_TOOLS = "SOURCE_LANDING_TOOLS",
  SOURCE_MEMBERSHIP_CATEGORY_LANDING = "SOURCE_MEMBERSHIP_CATEGORY_LANDING",
  SOURCE_MEMBERSHIP_LANDING = "SOURCE_MEMBERSHIP_LANDING",
  SOURCE_MEMBERSHIP_LANDING_COMPONENT = "SOURCE_MEMBERSHIP_LANDING_COMPONENT",
  SOURCE_MEMBERSHIP_LANDING_MENU = "SOURCE_MEMBERSHIP_LANDING_MENU",
  SOURCE_ORGANIC = "SOURCE_ORGANIC",
  SOURCE_PRE_CHECKOUT = "SOURCE_PRE_CHECKOUT",
  SOURCE_PRE_REGISTRATION_ALL_ACCESS = "SOURCE_PRE_REGISTRATION_ALL_ACCESS",
  SOURCE_PROJECT_CREATION = "SOURCE_PROJECT_CREATION",
  SOURCE_REGISTER_FUNNEL = "SOURCE_REGISTER_FUNNEL",
  SOURCE_REMARKETING_HERO = "SOURCE_REMARKETING_HERO",
  SOURCE_ROULETTE_HOME = "SOURCE_ROULETTE_HOME",
  SOURCE_SEARCH_POP_UP = "SOURCE_SEARCH_POP_UP",
  SOURCE_SOFE = "SOURCE_SOFE",
  SOURCE_STUDENT_MEMBERSHIP_LANDING = "SOURCE_STUDENT_MEMBERSHIP_LANDING",
  SOURCE_STUDENT_PLAN = "SOURCE_STUDENT_PLAN",
  SOURCE_TEMPLATE_PAAS_MENU = "SOURCE_TEMPLATE_PAAS_MENU",
  SOURCE_TEMPORAL_ACCESS_LANDING = "SOURCE_TEMPORAL_ACCESS_LANDING",
  SOURCE_TEMPORAL_OR_FREE = "SOURCE_TEMPORAL_OR_FREE",
  SOURCE_TRACK_LANDING = "SOURCE_TRACK_LANDING",
}

export enum CourseTypeEnum {
  COURSE_TYPE_B2B = "COURSE_TYPE_B2B",
  COURSE_TYPE_B2B_CLASSROOMS = "COURSE_TYPE_B2B_CLASSROOMS",
  COURSE_TYPE_DEGREE = "COURSE_TYPE_DEGREE",
  COURSE_TYPE_FREE = "COURSE_TYPE_FREE",
  COURSE_TYPE_MEMBERSHIP = "COURSE_TYPE_MEMBERSHIP",
  COURSE_TYPE_PAID = "COURSE_TYPE_PAID",
  COURSE_TYPE_ROCKSTAR = "COURSE_TYPE_ROCKSTAR",
  COURSE_TYPE_TEMPORAL_ACCESS = "COURSE_TYPE_TEMPORAL_ACCESS",
}

export enum CourseVideoTrailerSourceVideoSourceType {
  HLSV3 = "HLSV3",
  HLSV4 = "HLSV4",
  MP3 = "MP3",
  MP4 = "MP4",
  NONE = "NONE",
}

export enum DegreeLearningEventScheduleTargetEnum {
  TARGET_COMPLEMENTARY_SESSION = "TARGET_COMPLEMENTARY_SESSION",
  TARGET_COUNSELLING = "TARGET_COUNSELLING",
  TARGET_KICKOFF = "TARGET_KICKOFF",
}

export enum DegreeSyllabusStatusEnum {
  STATUS_CLOSURE = "STATUS_CLOSURE",
  STATUS_FINISHED = "STATUS_FINISHED",
  STATUS_ONBOARDING = "STATUS_ONBOARDING",
  STATUS_PROGRESS = "STATUS_PROGRESS",
  STATUS_SALE = "STATUS_SALE",
}

export enum EnrollmentTypeEnum {
  ENROLLMENT_B2B = "ENROLLMENT_B2B",
  ENROLLMENT_DEGREE = "ENROLLMENT_DEGREE",
  ENROLLMENT_FREE = "ENROLLMENT_FREE",
  ENROLLMENT_FREEMIUM = "ENROLLMENT_FREEMIUM",
  ENROLLMENT_FREE_MEMBERSHIP = "ENROLLMENT_FREE_MEMBERSHIP",
  ENROLLMENT_GITCARD = "ENROLLMENT_GITCARD",
  ENROLLMENT_MEMBERSHIP = "ENROLLMENT_MEMBERSHIP",
  ENROLLMENT_MENTOR = "ENROLLMENT_MENTOR",
  ENROLLMENT_PAID = "ENROLLMENT_PAID",
  ENROLLMENT_PREVIEW_TRIAL = "ENROLLMENT_PREVIEW_TRIAL",
  ENROLLMENT_PROFESSOR = "ENROLLMENT_PROFESSOR",
  ENROLLMENT_STAFF = "ENROLLMENT_STAFF",
  ENROLLMENT_TEMPORAL_ACCESS = "ENROLLMENT_TEMPORAL_ACCESS",
}

export enum LiveTargetEnum {
  TARGET_HIDDEN = "TARGET_HIDDEN",
  TARGET_PREMIUM = "TARGET_PREMIUM",
  TARGET_PRIVATE = "TARGET_PRIVATE",
  TARGET_PUBLIC = "TARGET_PUBLIC",
}

export enum MembershipTypeTag {
  BECADO = "BECADO",
  FAMILIAR = "FAMILIAR",
  ONG_BECADO = "ONG_BECADO",
  ORGANIZATION = "ORGANIZATION",
  PLUS = "PLUS",
  PREMIUM = "PREMIUM",
  PREMIUM_DUO = "PREMIUM_DUO",
  PREMIUM_LITE = "PREMIUM_LITE",
  PREMIUM_STUDENT = "PREMIUM_STUDENT",
  PROFESIONAL = "PROFESIONAL",
  UNIVERSITY_SCHOLARSHIP = "UNIVERSITY_SCHOLARSHIP",
}

export enum OnboardingEnum {
  ONBOARDING_FAMILY_PLAN = "ONBOARDING_FAMILY_PLAN",
  ONBOARDING_FREEMIUM = "ONBOARDING_FREEMIUM",
  ONBOARDING_FREE_MS = "ONBOARDING_FREE_MS",
  ONBOARDING_MEMBERSHIP = "ONBOARDING_MEMBERSHIP",
  ONBOARDING_NUMBER_FORM = "ONBOARDING_NUMBER_FORM",
  ONBOARDING_PAYG = "ONBOARDING_PAYG",
  ONBOARDING_SOFE = "ONBOARDING_SOFE",
}

export enum ParticipationCertificateTypeEnum {
  CERTIFICATE_TYPE_COURSE = "CERTIFICATE_TYPE_COURSE",
  CERTIFICATE_TYPE_MINEDU = "CERTIFICATE_TYPE_MINEDU",
  CERTIFICATE_TYPE_SIXTH_ANNIVERSARY = "CERTIFICATE_TYPE_SIXTH_ANNIVERSARY",
  CERTIFICATE_TYPE_WORKSHOP = "CERTIFICATE_TYPE_WORKSHOP",
}

export enum StatusProjectEnum {
  CERTIFIED = "CERTIFIED",
  DRAFT = "DRAFT",
  FINISHED = "FINISHED",
  OBSERVED = "OBSERVED",
  PUBLISHED = "PUBLISHED",
}

export enum StudyPlanFrequencyEnum {
  FREQUENCY_ALL = "FREQUENCY_ALL",
  FREQUENCY_FIVE = "FREQUENCY_FIVE",
  FREQUENCY_ONE = "FREQUENCY_ONE",
  FREQUENCY_THREE = "FREQUENCY_THREE",
  FREQUENCY_TWO = "FREQUENCY_TWO",
}

export enum StudyPlanTargetEnum {
  TARGET_CHILL = "TARGET_CHILL",
  TARGET_INTENSIVE = "TARGET_INTENSIVE",
  TARGET_REGULAR = "TARGET_REGULAR",
}

export enum UserTourSectionEnum {
  HOME_USER_DESKTOP_APPS_AD_DIALOG = "HOME_USER_DESKTOP_APPS_AD_DIALOG",
  HOME_USER_MOBILE_APPS_AD_DIALOG = "HOME_USER_MOBILE_APPS_AD_DIALOG",
  HOME_USER_SAVED_COURSES = "HOME_USER_SAVED_COURSES",
  MAIN_MENU_NIGHT_SWITCH = "MAIN_MENU_NIGHT_SWITCH",
  SECTION_COURSE_PLAYER = "SECTION_COURSE_PLAYER",
  SECTION_HOME_USER = "SECTION_HOME_USER",
  SECTION_HOME_USER_B2B = "SECTION_HOME_USER_B2B",
  SECTION_NEW_COURSES = "SECTION_NEW_COURSES",
  SECTION_SKILLPATH_CERTIFICATE_DIALOG = "SECTION_SKILLPATH_CERTIFICATE_DIALOG",
  SECTION_USER_DASHBOARD = "SECTION_USER_DASHBOARD",
  SNACKBAR_EDIT_USER_TASTES = "SNACKBAR_EDIT_USER_TASTES",
  STUDIOS_TOUR = "STUDIOS_TOUR",
  USER_DASHBOARD_SAVED_COURSES = "USER_DASHBOARD_SAVED_COURSES",
}

export enum UserTourStatusEnum {
  COMPLETED = "COMPLETED",
  IGNORE = "IGNORE",
  INCOMPLETE = "INCOMPLETE",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
